<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="1" />

    <div class="text-center">
      <h1 class="font-22 font-700">Choose Your Currency</h1>
      <p class="font-18">Set local pricing.</p>

      <v-autocomplete
        v-model="currency"
        :items="currencies"
        dense
        outlined
        placeholder="Select currency"
        :hide-details="true"
        item-text="currency"
        item-value="_id"
        background-color="white"
        class="mt-4"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            @click:close="remove(data.item)"
          >
            <span class="d-inline-block mr-2">{{ data.item.currency }}</span>
            <span class="d-inline-block mr-2">({{ data.item.symbol }})</span>
          </v-chip>
        </template>
      </v-autocomplete>
    </div>

    <div>
      <p class="font-16 font-500 text_grey text-center">Modify or use sample</p>
      <v-btn
        @click="submitHandler"
        :loading="loading"
        class="primary next-btn w-100"
        elevation="2"
        large
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import StepsCounter from "../StepsCounter.vue";
export default {
  components: {
    StepsCounter,
  },
  data() {
    return {
      currency: "6409084b411e99b341c1aaf2",
      currencies: null,
      loading: false,
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    async submitHandler() {
      if (!this.currency) {
        this.$store.dispatch("showSnack", {
          text: "Please choose a currency",
          color: "error",
        });

        return;
      }

      this.loading = true;
      let formData = new FormData();

      //console.log("this.obj.currency", this.obj.currency);
      formData.append("currencyId", this.currency);
      let res = await this.$axios.patch(process.env.VUE_APP_PROFILE, formData);
      if (res.data.success) {
        this.loading = false;
        this.$emit("next");
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: res.data.message || res.data.error || "Something went wrong!",
          color: "error",
        });
      }
    },
    async getProducts() {
      // ?searchWord=sp
      let { data } = await this.$axios.get(process.env.VUE_APP_CURRENCIES);
      this.currencies = data.currencies;
    },
  },
};
</script>

<style lang="scss" scoped>
.text_grey {
  color: rgba(112, 112, 112, 1);
}
</style>
