<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="3" />

    <div class="text-center">
      <h1 class="font-22 font-700">Begin Your Ofero Journey!</h1>
      <p class="font-18">Learn from examples up next.</p>
    </div>

    <v-btn @click="nextStep" class="primary next-btn w-100" elevation="2" large>
      Begin Now!
    </v-btn>
  </div>
</template>

<script>
import StepsCounter from "../StepsCounter.vue";
export default {
  components: {
    StepsCounter,
  },
  data() {
    return {};
  },
  methods: {
    nextStep() {
      this.$emit("next");
    },
  },
};
</script>
