<template>
  <div
    v-if="firstLogin"
    class="w-100 h-100 d-flex flex-column justify-space-between"
  >
    <div class="text-center mt-16">
      <h1 class="font-32 font-700">Welcome to Ofero!</h1>
      <p class="font-22">We're excited to have you on board.</p>
    </div>

    <v-btn @click="nextStep" class="primary next-btn w-100" elevation="2" large>
      Next
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstLogin: false,
    };
  },
  methods: {
    nextStep() {
      this.$emit("next");
    },
  },
  created() {
    if (this.profile) {
      if (this.profile.isFirstLogIn === false) {
        this.$router.push("/");
      } else {
        this.firstLogin = true;
      }
    }
  },
};
</script>
