<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="5" />

    <div class="text-center w-100">
      <h1 class="font-22 font-700">Add Your First Item</h1>
      <p class="font-18">Insert content details.</p>

      <form class="w-100">
        <v-row class="img-uploader h-100 mx-0">
          <v-col class="col-img h-100" md="12">
            <div class="relative cursor-pointer h-100">
              <img
                v-if="imageFormUpload.imgDataUrl || imageFormUpload.url"
                width="15px"
                class="upload-file-close"
                @click.stop="deleteImageOnClient"
                :src="require('@/assets/images/icons/close.svg')"
              />
              <img
                v-if="imageFormUpload.imgDataUrl || imageFormUpload.url"
                width="100%"
                height="100%"
                :src="imageFormUpload.imgDataUrl || imageFormUpload.url"
                :style="`object-fit: cover !important;`"
                alt=""
                class="selected-image"
              />
              <NewUploadBtn
                v-else
                text="Logo image"
                class="upload-logo w-100 h-100"
                @imagePicked="uploadImageHandler"
              >
              </NewUploadBtn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-0 mb-3">
            <label
              for="title"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600">Name: </span>
              <input
                id="title"
                type="text"
                class="w-100"
                v-model="form.title"
              />
            </label>
          </v-col>

          <v-col cols="12" class="py-0 mb-3">
            <label
              for="description"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600">Description: </span>
              <input
                id="description"
                type="text"
                class="w-100"
                v-model="form.description"
              />
            </label>
          </v-col>

          <v-col cols="12" class="py-0 mb-3">
            <label
              for="banner"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600">Banner: </span>
              <input
                id="banner"
                type="text"
                class="w-100"
                v-model="form.bannerText"
              />
            </label>
          </v-col>

          <v-col cols="12" class="py-0 mb-3" md="6">
            <label
              for="oldPrice"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600">Regular: </span>
              <input
                class="w-100"
                id="oldPrice"
                type="text"
                v-model="form.oldPrice"
              />
            </label>
          </v-col>

          <v-col cols="12" class="py-0 mb-3" md="6">
            <label
              for="price"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600">Sale: </span>
              <input
                class="w-100"
                id="price"
                type="text"
                v-model="form.price"
              />
            </label>
          </v-col>
        </v-row>
      </form>
    </div>

    <div>
      <p class="font-16 font-500 text_grey text-center">Modify or use samples</p>
      <v-btn
        @click="saveProduct"
        :loading="loading"
        class="primary next-btn w-100"
        elevation="2"
        large
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import NewUploadBtn from "@/components/base/NewUploadBtn.vue";
import StepsCounter from "../StepsCounter.vue";
import defaultItemImg from "@/assets/images/global/default-item-image.jpg";

export default {
  components: {
    StepsCounter,
    NewUploadBtn,
  },
  props: {
    payload: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      defaultItemImg,
      loading: false,
      form: {
        photo: null,
        title: "Floral Print Maxi Dress",
        description: "Elegant and perfect for spring evenings.",
        bannerText: "Special offer!",
        bannerType: "custom",
        price: 25,
        oldPrice: 40,
        isOffer: false,
        active: true,
        show: "image",
        imageShowType: "cover",
        videoShowType: "cover",
        video: null,
        links: [],
      },
      imageFormUpload: { imgDataUrl: null, url: null },
    };
  },
  mounted() {
    console.log(defaultItemImg);
    this.generateDefaultImage();
  },
  methods: {
    async generateDefaultImage() {
      // const defaultImageUrl =
      //   "/src/assets/images/global/default-item-image.jpg";
      const response = await fetch(defaultItemImg);
      const blob = await response.blob();
      const file = new File([blob], "default-image.jpg", { type: blob.type });

      this.uploadImageHandler(defaultItemImg, file);
    },
    uploadImageHandler(url, file) {
      let fileCustom = new File([file], file.name.replace(/ /g, "_"), {
        type: file.type,
      });
      this.imageFormUpload.imgDataUrl = url;
      this.imageFormUpload.url = fileCustom;
    },
    deleteImageOnClient() {
      this.imageFormUpload.imgDataUrl = "";
      this.imageFormUpload.url = "";
    },
    async saveProduct() {
      try {
        this.loading = true;

        if (!this.imageFormUpload.url) {
          this.loading = false;
          throw new Error("Image is required");
        } else if (this.imageFormUpload.url.size > 1024 * 1024 * 10) {
          this.loading = false;
          throw new Error("Image size must be less than 10MB");
        }

        const schema = this.form;
        schema.featured = this.form?.featured || false;
        schema.active = this.form?.active || false;
        schema.type = "template";
        schema.images = this.imageFormUpload.url;
        schema.counter = 5; // 5 seconds

        this.createProduct({ data: schema });
      } catch (err) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: err.message,
          color: "error",
        });
      }
    },
    async createProduct({ data }) {
      //console.log("thi close dialog");
      data.group = this.payload;
      let formData = new FormData();

      for (let key in data) {
        if (data[key]) {
          if (
            typeof data[key] != "object" ||
            key == "images" ||
            key == "video"
          ) {
            formData.append(key, data[key]);
          } else {
            formData.append(key, JSON.stringify(data[key]));
          }
        }
      }

      try {
        this.$store
          .dispatch("offersModule/saveProduct", {
            groupId: this.payload,
            formData,
          })
          .then((res) => {
            this.loading = false;
            this.$emit("next");
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.$store.dispatch("showSnack", {
              text: e.message || e.error || "Something went wrong!",
              color: "error",
            });
          });
      } catch (e) {
        this.loading = false;
        console.error(e);
        this.$store.dispatch("showSnack", {
          text: e ? e.message || e.error : "Something went wrong",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  background-color: rgba(244, 244, 244, 1);
  border-radius: 15px;
  padding: 9px 15px;
}

.img-uploader {
  width: 75px;
  height: 75px;
  background-color: #ddd;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.col-img {
  padding: 0 !important;
}
.selected-image {
  border-radius: 10px;
  object-fit: unset !important;
}
.upload-file-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
