<template>
  <div class="relative z-index-100 upload-file" @click="triggerUploader()">
    <div class="text-center upload-logo-text">
      <span class="plus font-30 d-block black--text">+</span>
      <!-- <span class="font-18 d-block black--text">Logo Image</span>
      <span class="font-15 d-block greytext--text"
        >JPG, <span class="font-600">PNG*</span></span
      > -->
    </div>

    <input
      ref="uploader"
      type="file"
      hidden
      id="id"
      accept="image/*"
      @change="onFileChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      default: Object,
    },
    text: {
      type: String,
      default: "",
    },
    borderColor: {
      type: String,
      default: "#f03",
    },
    mouseOn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    triggerUploader() {
      this.$refs.uploader.click();
    },
    onFileChange(e) {
      //console.log(e, e.target.files[0]);
      // createUrl to pick image to emit
      let url = URL.createObjectURL(e.target.files[0]);
      // open on new tab
      this.$emit(
        "imagePicked",
        url,
        e.target.files[0],
        this.imageSizeByMb(e.target.files[0])
      );
    },
    imageSizeByMb(img) {
      return img.size / 1024 / 1024;
    },
  },
};
</script>

<style lang="scss">
.upload-file {
  // border: 1px solid $primary;
  // background: $greenbg;
  transition: 0.3s;
  overflow: hidden;
  z-index: 100;
  border-radius: 20px;

  .plus {
    font-size: 50px;
    color: #0195a9 !important;
    font-weight: normal;
  }

  .v-input__icon,
  .v-input__prepend-outer,
  .v-input button,
  .v-input {
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
  }

  &__delete {
    position: absolute !important;
    top: 2px;
    right: 2px;
    z-index: 99;
  }
}
.upload-logo-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.z-index-100 {
}
</style>
