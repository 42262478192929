<template>
  <div class="d-flex justify-center gap-5">
    <div
      class="step-number"
      :class="{ active: step === currentStep }"
      v-for="step in steps"
      :key="step"
    >
      {{ step }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      steps: 7,
    };
  },
};
</script>

<style lang="scss">
.step-number {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(244, 244, 244, 1);

  &.active {
    background-color: rgba(253, 208, 57, 1);
  }
}
</style>
