<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="6" />

    <div class="text-center">
      <h1 class="font-22 font-700">Set Up Your First Screen</h1>
      <p class="font-18">Assign content to display.</p>

      <form class="w-100">
        <v-row>
          <v-col cols="12">
            <label
              for="title"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600 no-wrap">Screen Name: </span>
              <input
                id="title"
                type="text"
                class="w-100"
                v-model="form.title"
              />
            </label>
          </v-col>
        </v-row>
      </form>
    </div>

    <div>
      <p class="font-16 font-500 text_grey text-center">Modify or use sample</p>
      <v-btn
        @click="submitHandler"
        :loading="loading"
        class="primary next-btn w-100"
        elevation="2"
        large
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import StepsCounter from "../StepsCounter.vue";
export default {
  components: {
    StepsCounter,
  },
  props: {
    payload: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        title: "Display Screen 1",
      },
    };
  },
  methods: {
    async submitHandler() {
      this.loading = true;
      let obj = {
        groups: [
          {
            id: this.payload,
            sort: "0",
          },
        ],
        title: this.form.title,
      };

      this.$axios
        .post(process.env.VUE_APP_PREVIEWS, obj)
        .then((res) => {
          this.loading = false;
          this.$emit("next");
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong!",
            color: "error",
          });
        });
    },
  },
};
</script>
