<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="2" />

    <div class="text-center">
      <h1 class="font-22 font-700">Select Your Timezone</h1>
      <p class="font-18">Ensure correct timing.</p>

      <v-autocomplete
        v-model="timezone"
        :items="['GMT', ...Intl.supportedValuesOf('timeZone')]"
        dense
        outlined
        placeholder="Select timezone"
        :hide-details="true"
        background-color="white"
        class="mt-4"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            @click:close="remove(data.item)"
          >
            <span class="d-inline-block mr-2">{{ data.item }}</span>
          </v-chip>
        </template>
      </v-autocomplete>
    </div>

    <div>
      <p class="font-16 font-500 text_grey text-center">Modify or use sample</p>
      <v-btn
        @click="submitHandler"
        class="primary next-btn w-100"
        elevation="2"
        large
        :loading="loading"
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import StepsCounter from "../StepsCounter.vue";
export default {
  components: {
    StepsCounter,
  },
  data() {
    return {
      loading: false,
      timezone: "GMT",
      timezones: null,
    };
  },
  methods: {
    async submitHandler() {
      if (!this.timezone) {
        this.$store.dispatch("showSnack", {
          text: "Please choose a timezone",
          color: "error",
        });

        return;
      }

      this.loading = true;
      let formData = new FormData();

      formData.append("timeZone", this.timezone);
      let res = await this.$axios.patch(process.env.VUE_APP_PROFILE, formData);
      if (res.data.success) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Timezone edited successfully!",
          color: "success",
        });

        this.$emit("next");
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: res.data.message || "Something went wrong!",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.edit-btn {
  border-radius: 15px;
}
</style>
