<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="7" />

    <div class="text-center">
      <h1 class="font-32 font-700">Time to Preview!</h1>
      <p class="font-22">Great job on creating your first content.</p>
    </div>

    <v-btn
      @click="showPreview"
      class="primary next-btn w-100"
      elevation="2"
      large
    >
      Preview
    </v-btn>
  </div>
</template>

<script>
import StepsCounter from "../StepsCounter.vue";
export default {
  components: {
    StepsCounter,
  },
  props: {
    payload: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      form: {},
    };
  },
  methods: {
    nextStep() {
      this.$emit("next");
    },
    showPreview() {
      window.location.href = `${location.origin}`;
    },
  },
};
</script>
