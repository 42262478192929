<template>
  <div
    class="w-100 h-screen bg-white d-flex flex-column justify-center align-center px-10"
  >
    <img :src="require('@/assets/images/global/logo-blue.svg')" alt="" />

    <div
      class="steps-container d-flex flex-column justify-space-between align-center mt-10"
    >
      <component
        :is="stepsComponents[currentStep]"
        :payload="payload"
        @next="nextStep"
      />
    </div>
  </div>
</template>

<script>
import Welcome from "./components/steps/Welcome.vue";
import Currency from "./components/steps/Currency.vue";
import Timezone from "./components/steps/Timezone.vue";
import Begin from "./components/steps/Begin.vue";
import Group from "./components/steps/Group.vue";
import Product from "./components/steps/Product.vue";
import Screen from "./components/steps/Screen.vue";
import Preview from "./components/steps/Preview.vue";

export default {
  data() {
    return {
      payload: null,
      currentStep: 0,
      stepsComponents: [
        Welcome,
        Currency,
        Timezone,
        Begin,
        Group,
        Product,
        Screen,
        Preview,
      ],
    };
  },
  mounted() {
    if (this.profile && !this.profile.isFirstLogIn) {
      this.$router.push("/");
    }
  },
  methods: {
    nextStep(payload) {
      console.log("next", payload);
      if (this.currentStep === 7) {
        this.currentStep = 0;
        return;
      }

      if (payload) {
        this.payload = payload;
      }

      this.currentStep++;
    },
  },
};
</script>

<style lang="scss">
.steps-container {
  width: 100%;
  max-width: 567px;
  height: 596px;
  padding: 40px;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 1);

  .next-btn {
    height: 42px;
    border-radius: 15px;
  }

  input {
    &:focus {
      outline: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
}
</style>
